<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Sliders</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ <router-link to="/slider">Slider </router-link> \ Slider
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <a @click="createSliderType()" class="btn btn-primary font-weight-bolder font-size-sm">
                    <span class="svg-icon svg-icon-md svg-icon-white"></span>
                    Create New
                  </a>&nbsp;
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="form-group mx-2">
                <input type="text" placeholder="Title" class="border-outline form-control">
              </div>
              <div class="form-group mx-2">
                <select class="form-control">
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
              <div class="form-group mx-2">
                <button class="btn btn-primary">Search</button>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table" style="table-layout: fixed">
                <thead>
                <tr class="text-center">
                  <th class="text-center"><strong>TITLE</strong></th>
                  <th class="text-center"><strong>STATUS</strong></th>
                  <th class="pr-3 text-center"><strong>ACTION</strong></th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr class="text-center">

                    <td class="text-center">
                      <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >Natural</a>
                    </td>
                    <td class="text-center">
                       <span class="label label-lg label-inline"
                             v-bind:class="`label-light-success`"
                       >Active</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editSliderType()" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="orange darken-2">fas fa-edit</v-icon>
                                </span>
                                <span class="navi-text">Edit Slider</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="red">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr class="text-center">

                    <td class="text-center">
                      <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >Animal</a>
                    </td>
                    <td class="text-center">
                       <span class="label label-lg label-inline"
                             v-bind:class="`label-light-danger`"
                       >Inactive</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editSliderType()" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="orange darken-2">fas fa-edit</v-icon>
                                </span>
                                <span class="navi-text">Edit Slider</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="red">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr class="text-center">

                    <td class="text-center">
                      <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >Celebrity</a>
                    </td>
                    <td class="text-center">
                       <span class="label label-lg label-inline"
                             v-bind:class="`label-light-success`"
                       >Active</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editSliderType()" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="orange darken-2">fas fa-edit</v-icon>
                                </span>
                                <span class="navi-text">Edit Slider</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="red">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr class="text-center">
                    <td class="text-center">
                      <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >Space</a>
                    </td>
                    <td class="text-center">
                       <span class="label label-lg label-inline"
                             v-bind:class="`label-light-danger`"
                       >Inactive</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editSliderType()" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="orange darken-2">fas fa-edit</v-icon>
                                </span>
                                <span class="navi-text">Edit Slider</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="red">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr class="text-center">
                    <td class="text-center">
                      <a href="#" class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >Football</a>
                    </td>
                    <td class="text-center">
                       <span class="label label-lg label-inline"
                             v-bind:class="`label-light-success`"
                       >Active</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editSliderType()" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="orange darken-2">fas fa-edit</v-icon>
                                </span>
                                <span class="navi-text">Edit Slider</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="red">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
        v-model="dialog"
        height="500px"
        max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Slider Type</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                    outlined
                    dense
                    label="Slider Title*"
                    v-model="slider.title">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-switch
                    v-model="slider.status"
                    label="Status"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              text
              @click="dialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
              class="btn btn-primary"
              @click="dialog = false"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>

export default {
  data() {
    return{
      dialog:false,
      types: [
        { text: 'Natural',value:'natural'},
        { text: 'Celebrity',value:'celebrity'},
        { text: 'Animal',value:'animal' },
        { text: 'Sports',value:'sports' },
        { text: 'Music',value:'music' },
        { text: 'Space',value:'space' },
      ],
      slider:{
        title:'',
        status:'',

      }
    }
  },
  methods: {
    closeDialog(){
      this.dialog=false;
    },
    openDialog(){
      this.dialog=true;
    },
    createSliderType(){
      this.resetForm();
      this.openDialog();
    },
    editSliderType(){
      this.openDialog();
      this.slider={
        title:"Football",
        status:false,
      }

    },
    resetForm(){
      this.openDialog();
      this.slider={
        title:"",
        status:true,
      }
    },
    sliderType(){
      this.$router.push({
        name: "slider-type",
      });
    }
    /*candidateSummary(){
      this.$router.push({
        name: "candidate-summary",
        params: { profile: "12" }
      });
    }*/
  }
}
</script>

